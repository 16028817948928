import React from "react";
import Image from "gatsby-image";
import { graphql, Link } from "gatsby";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardImgOverlay
} from "reactstrap";

import Layout from "../components/layout";

import ContactModule from "../components/modules/contact";
import SEO from "../components/SEO";

import styles from "./news.module.css";

export default ({ data }) => (
  <Layout>
    <SEO
      title="News"
      seoTitle="Eingang Bookings"
      description="Eingang Bookings agency news page."
    />
    <Thumbnails data={data.allMarkdownRemark.edges} />
    <ContactModule />
  </Layout>
);

const Thumbnail = props => (
  <Col xs="12" md="6" lg="4" className="mb-4" key={props.key}>
    <Link to={props.data.fields.slug}>
      <Card>
        <Image
          className={styles.image}
          fluid={
            props.data.frontmatter.featureImage.image.childImageSharp.fluid
          }
        />

        <CardImgOverlay className={`d-flex ` + styles.cardImgOverlay}>
          <CardTitle tag="p" className={`h2 mt-auto ` + styles.textOverlay}>
            <span className={styles.cardOverlayBackground}>
              {props.data.frontmatter.title}
            </span>
          </CardTitle>
        </CardImgOverlay>
      </Card>
    </Link>
  </Col>
);

const Thumbnails = props => (
  <Container id="news">
    <Row className="pt-4">
      <Col>
        <Link className={styles.smallFont} to="/">
          Back to frontpage
        </Link>
      </Col>
    </Row>
    <Row>
      <Col>
        <h1>News</h1>
      </Col>
    </Row>
    <Row className="aboutBody">
      {props.data.map(({ node }) => (
        <Thumbnail key={node.id} data={node} />
      ))}
    </Row>
  </Container>
);

export const query = graphql`
  query NewsQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/news/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            modifyDate(formatString: "MMMM Do, YYYY")
            featureImage {
              image {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
